import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { SECTION_TOP_PADDING, SECTION_MARGIN } from '../../utils/constants'
// Types
import { PageContextProps, PageInfoProps, PaginationContextProps, PublicationProps } from '../../types/pages'
import { CategoryProps, PostPreviewWithCategoriesProps } from '../../types/blog'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Layout, Seo } from '../../components/commons'
import { Box, Heading } from '../../atoms'
import PaginatedPosts from '../../components/blog/commons/PaginatedPosts'
import CategoriesList from '../../components/blog/commons/CategoriesList'

interface CategoryQuery {
  blogUtils: {
    pageInfo: PageInfoProps
    allPostsLabel: string
    paginationPreviousLabel: string
    paginationNextLabel: string
  }
  category: CategoryProps & {
    meta: PublicationProps
  }
  categoryList: {
    nodes: CategoryProps[]
  }
  postList: {
    nodes: PostPreviewWithCategoriesProps[]
  }
}

type PageContext = PageContextProps & PaginationContextProps

const Category: React.FC<PageProps<CategoryQuery, PageContext>> = ({ pageContext, data }) => {
  const { totalPages, currentPage, previousPagePath, nextPagePath } = pageContext
  const {
    blogUtils: { pageInfo: blogPageInfo, allPostsLabel, paginationPreviousLabel, paginationNextLabel },
    category: { meta, pageInfo, name },
    categoryList: { nodes: categories },
    postList: { nodes: posts },
  } = data

  return (
    <Layout pageId={pageContext.id} locale={pageContext.locale} allSlugLocales={pageContext.slugLocales}>
      <Seo
        slug={pageInfo.slug}
        canonicalUrl={pageInfo.canonicalUrl}
        locale={pageContext.locale}
        allSlug={pageContext.slugLocales}
        publication={meta}
        content={pageInfo.seo}
        preventIndexing={pageInfo.preventIndexing}
      />

      <Box pt={SECTION_TOP_PADDING} pb={[6, 7, 8]}>
        <Grid>
          <Row>
            <Col xs={12} md={8}>
              <Heading type={'display'}>{name}</Heading>
            </Col>
          </Row>
        </Grid>
      </Box>

      <Box>
        <Grid>
          <Row>
            <Col xs={12}>
              {/* Categories */}
              <CategoriesList
                blogLandingPageInfo={blogPageInfo}
                allLabel={allPostsLabel}
                categories={categories}
                isAll={false}
              />

              <Box mb={SECTION_MARGIN}>
                <PaginatedPosts
                  posts={posts}
                  prevLabel={paginationPreviousLabel}
                  previousPagePath={previousPagePath}
                  nextLabel={paginationNextLabel}
                  nextPagePath={nextPagePath}
                  totalPages={totalPages}
                  currentPage={currentPage}
                />
              </Box>
            </Col>
          </Row>
        </Grid>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query CategoryQuery($id: String!, $locale: String!, $skip: Int!, $limit: Int!) {
    blogUtils: datoCmsBlog(locale: $locale) {
      pageInfo {
        ...pageInfoFragment
      }
      allPostsLabel
      paginationPreviousLabel
      paginationNextLabel
    }
    category: datoCmsCategory(id: { eq: $id }, locale: $locale) {
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      ...categoryFragment
    }
    categoryList: allDatoCmsCategory(locale: $locale, sort: { fields: name }) {
      nodes {
        ...categoryFragment
      }
    }
    postList: allDatoCmsPost(
      locale: $locale
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: meta___firstPublishedAt }
      filter: {
        _allContentLocales: { elemMatch: { locale: { eq: $locale } } }
        categories: { elemMatch: { id: { eq: $id } } }
      }
    ) {
      nodes {
        ...postPreviewWithCategoriesFragment
      }
    }
  }
`

export default Category
